<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Dynamic Download Center</a-divider></a-col>

    </a-row>

<a-row>
    <a-col :span="24">

    <a-spin :spinning="appCurrentSettings.saved_query_dynamic_file_list_loading">






    <h2>{{appCurrentSettings.current_friendly_name}}</h2>
    <p>Select input parameter and click download&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a-span style="padding-left:0px!important;color:#4292fd;"  @click="() => {if(this.replaceParamValue()){this.appCurrentSettings.sqlCodeModal = true}}">SQL</a-span></p>
    

      
    <br><br>
    <a-row >

        <a-col :span="24">
           <a-form
    ref="formRef"
    name="dynamic_form_nest_item"
    :model="dynamicValidateForm"
    @finish="onFinish"
    
  >
    
      <a-form-item>
      <a-row style="width:100%;margin-bottom:10px;">
        <a-col :span="6" style="font-weight:700">
            Parameter Name
        </a-col>
        <a-col :span="6" style="padding-left:10px;font-weight:700">
            Type
        </a-col>
        
        <a-col :span="12" style="padding-left:10px;font-weight:700">
            Select Value
        </a-col>
      </a-row>
    
    <a-row
      v-for="(k) in appCurrentSettings.currentDynamicParamString.params"
      :key="k.id"
      style="display: flex; margin-bottom: 16px; width:100%"
      align="baseline"
      wrap
      class="parameter-space"

    >
    <a-col :span=24>
      <a-row style="margin-bottom:10px;">
        <a-col :span="6">
            {{k.param_name}}
        </a-col>
        <a-col :span="6"  style="padding-left:10px">
            <a-select
            ref="select"
            v-model:value="k.param_type"
            @focus="focus"
            disabled
            >
                <a-select-option value="TEXT">Text</a-select-option>
                <a-select-option value="DATE">Date</a-select-option>
                <a-select-option value="MULTIPLE_SELECT">Multiple Select</a-select-option>
                <a-select-option value="SINGLE_SELECT">Single Select</a-select-option>
            </a-select>
        </a-col>
        <a-col :span="12" style="padding-left:10px">
            <a-input v-if="k.param_type=='TEXT'" v-model:value="k.default_value" placeholder="Input Value" />
            <a-date-picker v-if="k.param_type=='DATE'" v-model:value="k.default_value" valueFormat="YYYY-MM-DD"/>
            <a-select
                v-if="k.param_type=='MULTIPLE_SELECT'"
                v-model:value="k.default_value"
                mode="multiple"
                :size="size"
                :options="k.possible_value.map(value => ({ value: value, label: value }))"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
            <a-select
                v-if="k.param_type=='SINGLE_SELECT'"
                v-model:value="k.default_value"
                :options="k.possible_value.map(value => ({ value: value, label: value }))"
                mode="options"
                :size="size"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
        </a-col>
      </a-row>
      </a-col>
    </a-row>
      </a-form-item>
    
    
    
  </a-form>
  <a-divider/>
<a-button type="primary" style="float:right;margin-right:40px;" @click="submitRunData">Submit Download</a-button>


        </a-col>
    </a-row><br><br><br>

    <a-row>
        <a-col :span="24" style="text-align:center">
            <a-progress v-if="appCurrentSettings.is_begin_download == true && appCurrentSettings.download_url == ''" :percent="80" status="active" />
            <a-progress v-if="appCurrentSettings.is_begin_download == true && appCurrentSettings.download_url != ''" :percent="100" />
            <a-button v-if="appCurrentSettings.is_begin_download == true && appCurrentSettings.download_url != ''" style="margin-top:40px;" :href="appCurrentSettings.download_url" target="_blank" >Download File</a-button>
        </a-col>
    </a-row><br>


    </a-spin>
    </a-col>
</a-row>
</a-layout-content>


<a-modal v-model:open="appCurrentSettings.sqlCodeModal" title="SQL" @ok="() => {this.appCurrentSettings.sqlCodeModal = false}">
      <p>{{appCurrentSettings.finalQuery}}</p>
</a-modal>

</template>

<script>
import {
    defineComponent,
    ref,
    // reactive
} from 'vue';
import {
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

import axios from 'axios';
axios.defaults.withCredentials = true


export default defineComponent({
    components: {
    },
    data() {
        const appCurrentSettings = {}
        appCurrentSettings.data = []
        appCurrentSettings.columns = [
                                        {
                                            name: 'Name',
                                            dataIndex: 'name',
                                            key: 'name',
                                        },
                                        {
                                            title: 'Create Date',
                                            dataIndex: 'data_date',
                                            key: 'data_date',
                                        },
                                        {
                                            title: 'Status',
                                            key: 'status',
                                            dataIndex: 'status',
                                        },
                                        {
                                            title: 'Action',
                                            key: 'action',
                                        },
                                        ];
        appCurrentSettings.is_table_list_loading = false
        appCurrentSettings.is_export_data_loading = {}
        appCurrentSettings.current_friendly_name = ''
        appCurrentSettings.file_id = ''
        appCurrentSettings.currentDynamicParamString = {'params':{},'query':''}
        appCurrentSettings.finalQuery = ''
        appCurrentSettings.download_url = ''
        appCurrentSettings.is_begin_download = false

        return {
            appCurrentSettings:appCurrentSettings,
        }
    },
    methods:{
        replaceParamValue(){
            this.appCurrentSettings.finalQuery = this.appCurrentSettings.currentDynamicParamString.query
            for (let i = 0; i < Object.keys(this.appCurrentSettings.currentDynamicParamString.params).length; i++) {
                let current_selected_value = this.appCurrentSettings.currentDynamicParamString.params[Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]]['default_value']
               if(current_selected_value == null || current_selected_value == '' || current_selected_value == 'null' || current_selected_value.length == 0){
                this.appCurrentSettings.finalQuery = ''
                alert('Please check parameter')
                return false
               }
               if(this.appCurrentSettings.currentDynamicParamString.params[Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]]['param_type']!= 'MULTIPLE_SELECT'){
                    let replaced_value = this.appCurrentSettings.currentDynamicParamString.params[Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]]['default_value']
                    let current_param = `{{${Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]}}}`
                    this.appCurrentSettings.finalQuery = this.appCurrentSettings.finalQuery.replaceAll(current_param,`'${replaced_value}'`)
                    
                }else{
                    let replaced_value = this.appCurrentSettings.currentDynamicParamString.params[Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]]['default_value'].join("','")
                    let current_param = `{{${Object.keys(this.appCurrentSettings.currentDynamicParamString.params)[i]}}}`
                    this.appCurrentSettings.finalQuery = this.appCurrentSettings.finalQuery.replaceAll(current_param,`('${replaced_value}')`)
                }
            }
            return true
        },
        submitRunData(){
            let mode = 'FULL_ADHOC_RUN'
            if(!this.replaceParamValue()){
                return false
            }
            this.appCurrentSettings.download_url = ''
            this.appCurrentSettings.is_begin_download = true
            let current_file_id = ''
            if(mode == 'FULL_ADHOC_RUN' && this.appCurrentSettings.file_id == ''){
                current_file_id = this.generateNewFileId('ADHOC','temp_file')
            }else{
                current_file_id = this.appCurrentSettings.file_id
                this.appCurrentSettings['is_loading_result_preview'] = true
            }
            this.appCurrentSettings['is_loading_result_preview'] = true
            let data = {
                // Data you want to send in the request body
                mode: mode,
                file_id: current_file_id,
                query_content: this.appCurrentSettings.finalQuery,
                };
            axios
            .post(this.$root.app_api_endpoint+'/submit_run_query'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    console.log(response.data.data.job_id)
                    this.appCurrentSettings['current_running_job_id'] = response.data.data.job_id
                    
                    window.checkSubmittedTaskInterval = setInterval(this.checkRunningResult, 5000,mode,response.data.data.job_id,response.data.data.resultPathUri);
                    
                    message.success('Submit Success!');
                }
            })
        },
        checkRunningResult(mode,job_id,fileDownloadUri){
            this.appCurrentSettings.download_url = ''
            axios
            .get(this.$root.app_api_endpoint+'/check_query_status?job_id='+job_id+'&mode='+mode+'&result_path='+fileDownloadUri+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.data.run_state == 'DONE') {
                    this.appCurrentSettings['is_loading_result_preview'] = false
                    this.appCurrentSettings['current_running_job_id'] = response.data.job_id
                    // this.checkRunningResult(mode,response.data.job_id,response.data.resultPathUri)
                    clearInterval(window.checkSubmittedTaskInterval)
                    if (response.data.data.error_message != ''){
                        message.error(response.data.data.error_message.message)
                        document.getElementById('queryTablePreviewInfo').style.display = 'none'
                        document.getElementById('queryAlertInfo').style.display = 'unset'
                        this.appCurrentSettings.queryErrorMessage = response.data.data.error_message.message
                        this.appCurrentSettings.current_table_column = []
                        this.appCurrentSettings.current_table_data_source = []

                    }else if (mode=='FULL_ADHOC_RUN' && response.data.data.error_message == ''){
                        this.appCurrentSettings.download_url = response.data.data.result_file_url
                        window.open(response.data.data.result_file_url, '_blank');
                        this.appCurrentSettings.current_table_column = []
                        this.appCurrentSettings.current_table_data_source = []

                    }
                }else if(response.data.run_state == 'RUNNING') {
                    console.log('running')
                }
            })

        },
        loadDynamicFileList(){
            this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_saved_dynamic_query'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_dynamic_file_list = response.data.data
                    this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                }
        })
        },
        renderDynamicParameter(){
            const regex = /\{\{.+?\}\}/g;
            if(this.appCurrentSettings.current_query_content.match(regex) == null){
                this.appCurrentSettings.currentDynamicParamString.params = {}
                return false
            }
            const params_list = this.appCurrentSettings.current_query_content.match(regex).map(y => y.replaceAll(/\{|\}/g,''))
           
            this.appCurrentSettings.currentDynamicParamString.query = this.appCurrentSettings.current_query_content
            
            
            for (let i = 0; i < params_list.length; i++) {
                if(Object.keys(this.appCurrentSettings.currentDynamicParamString.params).includes(params_list[i])){
                    
                    continue
                }
                console.log('create param',params_list[i])
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]] = ref({})
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].param_name = params_list[i]
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].param_type = ref('TEXT')
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].default_value = null
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].possible_value = ref()
                
                
            }
            
            let difference = Object.keys(this.appCurrentSettings.currentDynamicParamString.params).filter(x => !params_list.includes(x));
            for (let i = 0; i < difference.length; i++) {
                delete this.appCurrentSettings.currentDynamicParamString.params[difference[i]]
            }

            return true
        },
        openSavedFile(file_id){
            this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/open_saved_query?file_id='+file_id+`&timestamp=${new Date().getTime()}`)
            .then((response_raw) => {
                let response = response_raw.data
                if (response.success == true) {
                    this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings.currentDynamicParamString = JSON.parse(response.data)
                    this.appCurrentSettings.current_query_content = ''
                    this.appCurrentSettings['current_query_content'] = JSON.parse(response.data)['query']
                    this.componentKey++;
                    this.appCurrentSettings['current_friendly_name'] = file_id.split('_')[3]
                    this.renderDynamicParameter()
                    message.success('Open Success!');
                    }else{
                        this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                        message.error('Open file error');
                    }
                    
                }
            )

            
            
        },
        
    },
    

    created() {
        // message('Done')
        // this.loadTablesList()
        let download_mode = new URLSearchParams(window.location.search).get('mode')
        if(download_mode == 'public'){
            document.getElementsByClassName('ant-layout-sider')[0].style.display = 'none'
        }
        

        let file_id = new URLSearchParams(window.location.search).get('id')
        if(file_id != null){
            this.openSavedFile(file_id)
        }else{
            message.error('File ID not found');
        }
    },
    mounted(){
        // CodeMirror.fromTextArea(document.getElementById('main-code-editor'),{
        //     lineNumbers:true
        // })
    }

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
}
</style>