<template>
 <title>BabyMoby - DMS</title>
<a-layout>
    <Headers :user="user"  />
    <a-layout>
        <Nav_bar />
        <a-layout style="padding: 0 12px 12px">
            <RouterView></RouterView>
        </a-layout>
    </a-layout>
</a-layout>
</template>

<script>
import Nav_bar from '@/components/AppNavBar.vue'
import Headers from '@/components/AppHeaders.vue'
import axios from 'axios';
import {
    defineComponent
} from 'vue';
axios.defaults.withCredentials = true
export default defineComponent({
    components: {
        Nav_bar,
        Headers,

    },
    data() {
        return {
            user: [],
            // app_api_endpoint: 'http://localhost:8081'
            app_api_endpoint: 'https://babymoby-dms-api.onedataconnect.com'
        }
    },
    mounted() {
        axios
            .get(this.app_api_endpoint+'/user',{withCredentials: true})
            .then((response) => {
                if (response.data.success == true) {
                    this.user = response.data.user
                    // window.location.href = '/task_management'
                }
                else if(response.data.success == false){
                    // alert('Not login')
                    window.location.href = response.data.login_url
                }

            })
    }

});
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
