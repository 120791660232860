<template>
<code-mirror v-model="userInput" 
                :lang="lang"
                :linter="linter"
                lineNumbers=true
                basic
                tab
                class="edit-area"
                id="edit-area"
                @change="emitMessage"
                />
</template>

<script>
import {
    defineComponent,
    ref
} from "vue";
import CodeMirror from 'vue-codemirror6';
import { sql } from "@codemirror/lang-sql";

export default defineComponent({
    name: 'codeEditor',
    props: {
        codeValue: {
            type: Object,
        },
    },
    components: {
     CodeMirror
    },
    data(){
        return {
            CodeMirror,
     sql,
     lang : sql(),
     linter:null,
     userInput:ref(this.codeValue),
        }
    },
    setup() {

        const projMain = ref({})

        return {
            projMain,
        };
    },
    methods: {
        emitMessage() {
      this.$emit('updateMessage', this.userInput);
    },
    },
})
</script>

<style scoped>
.edit-area {
  min-height: 400px;
    max-height: 400px;
    overflow: scroll;
    border: 1px solid #999999;
}
</style>
