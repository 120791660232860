import { createRouter, createWebHistory } from 'vue-router'
 


// import BasicSPUDataPage from '../views/BasicSPUPage.vue'
import HomePage from '../views/HomePage.vue'
import QueryStudio from '../views/SPUQuery.vue'
import ScheduleDownload from '../views/ScheduleDownload.vue'
import DynamicDownloadCenter from '../views/DynamicDownloadCenter.vue'
import DynamicDownload from '../views/DynamicDownload.vue'
import DataImport from '../views/DataImport.vue'
import SettingPage from '../views/SettingsPage.vue'
const routes = [
  { 
    path: '/',
    name: 'home-page',
    component: HomePage,
    meta: {title: 'BabyMoby DMS - Home'}
  },
  {
    path: "/query-studio",
    name: "QueryStudio",
    component: QueryStudio,
    meta: {title: 'BabyMoby DMS - Query Studio'}
   },
   {
    path: "/report-center",
    name: "ReportCenter",
    component: ScheduleDownload,
    meta: {title: 'BabyMoby DMS - Report Center'}
   },
   {
    path: "/dynamic-download-center",
    name: "DynamicDownloadCenter",
    component: DynamicDownloadCenter,
    meta: {title: 'BabyMoby DMS - Dynamic Download'}
   },
   {
    path: "/dynamic-download",
    name: "DynamicDownload",
    component: DynamicDownload,
    meta: {title: 'BabyMoby DMS - Dynamic Download'}
   },
   {
    path: "/data-import",
    name: "DataImport",
    component: DataImport,
    meta: {title: 'BabyMoby DMS - Data Import'}
   },
   {
    path: "/setting-page",
    name: "SettingPage",
    component: SettingPage,
    meta: {title: 'BabyMoby DMS - Settings'}
   },


]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


