<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Tools</a-divider></a-col>

    </a-row>


<a-row justify="start">
    <a-col :span="24">
        <div>
        <!-- <a href="/query-studio"> -->
        <router-link to="/query-studio">
         <a-card hoverable class="product-item">
           <a-row>
            <a-col :span="4">
                <!-- <img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"  style="width:50px;"> -->
                
        <CodeOutlined style="font-size:4em" />
            </a-col>
           </a-row>
           <p class="product-header"><b>Query Studio</b></p>
           <p class="product-subheader">SQL Editor that can generate data from Data Warehouse, Set Schedule, and Create Dynamic Download</p>
        </a-card>
        <!-- </a> -->
        </router-link>

        <router-link to="/report-center">
        <a-card hoverable class="product-item">
           <a-row>
            <a-col :span="4">
                <FileDoneOutlined style="font-size:4em" />
            </a-col>
           </a-row>
           <p class="product-header"><b>Report Center</b></p>
           <p class="product-subheader">A center portal to download scheduled task</p>
        </a-card>
        </router-link>

        <router-link to="/dynamic-download-center">
        <a-card hoverable class="product-item">
           <a-row>
            <a-col :span="4">
                <AppstoreAddOutlined style="font-size:4em" />
            </a-col>
           </a-row>
           <p class="product-header"><b>Dynamic Download</b></p>
           <p class="product-subheader">A center portal to download dynamic task</p>
        </a-card>
        </router-link>

        <router-link to="/data-import">
        <a-card hoverable class="product-item">
           <a-row>
            <a-col :span="4">
                <ImportOutlined style="font-size:4em" />
            </a-col>
           </a-row>
           <p class="product-header"><b>Data Import</b></p>
           <p class="product-subheader">A center portal to import offline data to data warehouse system</p>
        </a-card>
        </router-link>


        </div>
    </a-col>
</a-row>
<br><br><br><br><br><br>
<a-row>
    <a-col :span="24"><a-divider orientation="left">Update Status</a-divider></a-col>

    </a-row>


<div class="parent-desc">
<div class="desc">
      <p style="font-size: 16px">
        <strong>Data Update Status:</strong>
      </p>
      


      <p v-if="orderApiRefreshStatus['lazada_spu']==true">
            <CheckCircleOutlined :style="{ color: 'green' }" />
        Lazada SPU Updated Successfully. (2024-01-01)
      </p>

      <p v-if="orderApiRefreshStatus['lazada_spu']==true">
            <CheckCircleOutlined :style="{ color: 'green' }" />
        Lazada SPU Updated Successfully. (2024-01-01)
      </p>

      <p v-if="orderApiRefreshStatus['lazada_spu']==true">
            <CheckCircleOutlined :style="{ color: 'green' }" />
        Lazada SPU Updated Successfully. (2024-01-01)
      </p>

      <p v-if="orderApiRefreshStatus['lazada_spu']==true">
            <CheckCircleOutlined :style="{ color: 'green' }" />
        Lazada SPU Updated Successfully. (2024-01-01)
      </p>

      <p v-if="orderApiRefreshStatus['lazada_spu']==true">
            <CheckCircleOutlined :style="{ color: 'green' }" />
        Lazada SPU Updated Successfully. (2024-01-01)
      </p>


    </div>
</div>
    <!-- <p class="header_content">Home view</p> -->

    <!-- <modal_create_task></modal_create_task> -->
    <!-- <draggable v-model="formState" key="id">
        <template #item="{element}">
            <div>{{ element }}</div>
        </template>

    </draggable> -->
    <!-- <p>{{ formState }}</p> -->
    
</a-layout-content>
</template>

<script>
import {
    defineComponent,
    reactive,
} from 'vue';
import {
    // DesktopOutlined,
    // FileDoneOutlined
    CheckCircleOutlined,
    CodeOutlined,
    FileDoneOutlined,
    AppstoreAddOutlined,
    ImportOutlined,
} from '@ant-design/icons-vue';

// import modal_create_task from '@/components/MgmtModalSuccess.vue'
// import draggable from 'vuedraggable';


export default defineComponent({
    components: {
        CheckCircleOutlined,
         CodeOutlined,
    FileDoneOutlined,
    AppstoreAddOutlined,
    ImportOutlined,
    },
    data() {
        const defaultFormState = reactive({
            taskName: '',
            submissionFormFormat: {
                data: [],
            },
            frequency: {},
            countryPIC: [],
            taskRegion: [],
        });
        const orderApiRefreshStatus = {}
        orderApiRefreshStatus['lazada_spu'] = null
        orderApiRefreshStatus['shopee_spu'] = null
        orderApiRefreshStatus['tiktok_spu'] = null
        orderApiRefreshStatus['line_spu'] = null
        orderApiRefreshStatus['woocommerce_spu'] = null

        return {
            drag: false,
            formState: reactive({
                ...defaultFormState
            }),
            iconLoading: false,
            successModal: false,
            submitResponse: '',
            failedModal: false,
            orderApiRefreshStatus: orderApiRefreshStatus
        }
    },

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
    height: 260px;

}
.product-header{
    margin-top: 20px;
}
.product-subheader{
    color: #929292;
    font-size: 0.8em;
}
.parent-desc{
    background-color: rgba(0, 0, 0, 0.02);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.desc p {
  margin-bottom: 1em;
  
}
</style>