<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Dynamic Download Center</a-divider></a-col>

    </a-row>

<a-row>
    <a-col :span="24">


<a-table :columns="appCurrentSettings.columns" :pagination="pagination" :data-source="appCurrentSettings.data" :loading="appCurrentSettings.is_table_list_loading">
    <template #headerCell="{ column }">
      <template v-if="column.key === 'name'">
        <span>
          <smile-outlined />
          Name
        </span>
      </template>
    </template>

    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'name'">
        <a :href="'/dynamic-download?id='+record.name.split('.')[0]" target="_blank">
          {{ record.name.split('_')[3].split('.')[0] }}
        </a>
      </template>
      <template v-else-if="column.key === 'data_date'">
        <span>
           
           
            
            {{new Date(record.name.split('_')[1] * 1000).toISOString().split('T')[0]}}

            
        
        </span>
      </template>
      <template v-else-if="column.key === 'status'">
        <span>
          <a-tag
            :key="status"
            :color="record.status === 'READY' ? 'green' :  'red'"
          >
            {{ record.status.toUpperCase() }}
          </a-tag>
        </span>
      </template>
      <template v-else-if="column.key === 'action'" >
        <span style="text-align:center;width:100%;display:block;padding-right:20px;overflow:hidden">
        <a-dropdown-button :loading="appCurrentSettings.is_export_data_loading[record.name]" :href="'/dynamic-download?id='+record.name.split('.')[0]" target="_blank">
            <DownloadOutlined />
            Download
            <template #overlay>
                <a-menu>
                <a-menu-item key="1">
                    <UserOutlined />
                    Edit Code
                </a-menu-item>
                </a-menu>
            </template>
            </a-dropdown-button>
        </span>
      </template>
    </template>
  </a-table>



    </a-col>
</a-row>
</a-layout-content>

</template>

<script>
import {
    defineComponent,
    // ref,
    // reactive
} from 'vue';
import {
    DownloadOutlined,
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

import axios from 'axios';
axios.defaults.withCredentials = true


export default defineComponent({
    components: {
        DownloadOutlined,
    },
    data() {
        const appCurrentSettings = {}
        appCurrentSettings.data = []
        appCurrentSettings.columns = [
                                        {
                                            name: 'Name',
                                            dataIndex: 'name',
                                            key: 'name',
                                        },
                                        {
                                            title: 'Create Date',
                                            dataIndex: 'data_date',
                                            key: 'data_date',
                                        },
                                        {
                                            title: 'Status',
                                            key: 'status',
                                            dataIndex: 'status',
                                        },
                                        {
                                            title: 'Action',
                                            key: 'action',
                                        },
                                        ];
        appCurrentSettings.is_table_list_loading = false
        appCurrentSettings.is_export_data_loading = {}
        return {
            appCurrentSettings:appCurrentSettings,
        }
    },
    methods:{
        loadDynamicFileList(){
            this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_saved_dynamic_query'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_dynamic_file_list = response.data.data
                    this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                }
        })
        },
        loadTablesList(){
            // alert(this.$root.app_api_endpoint+'/list_tables?dataset_id='+this.appCurrentSettings.table_dataset)
            this.appCurrentSettings.is_table_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_saved_dynamic_query'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.is_table_list_loading = false
                    this.appCurrentSettings.data = response.data.data.map((item, index) => ({
                            key: index.toString(),
                            name: item,
                            status: 'READY',
                            action: null
                            })).filter((item) => item.name.includes('DMS-DYNAMIC_') )
                            .sort((a, b) => {
                            // Compare by date descending
                            if (a.name.split('_')[1] < b.name.split('_')[1]) return 1;
                            if (a.name.split('_')[1] > b.name.split('_')[1]) return -1;

                            // If dates are equal, compare by name ascending
                            return a.name.split('_')[3].localeCompare(b.name.split('_')[3]);
                            });
                            
                            


                }
            })
        },
        exportTable(table_id){
            this.appCurrentSettings.is_export_data_loading[table_id] = true
            let data = {
                table_id: table_id
                };
            axios
            .post(this.$root.app_api_endpoint+'/export_table'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {

                    window.open(response.data.data.url, '_blank');
                    message.success('Download Success!');
                    this.appCurrentSettings.is_export_data_loading[table_id] = false
                }else{
                    message.error('Error, please refresh and retry');
                    this.appCurrentSettings.is_export_data_loading[table_id] = false
                }
            })
        },
        
    },
    

    created() {
        // message('Done')
        this.loadTablesList()
    },
    mounted(){
        // CodeMirror.fromTextArea(document.getElementById('main-code-editor'),{
        //     lineNumbers:true
        // })
    }

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
}
</style>