
import { createApp  } from "vue";
import App from "./App.vue";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
// import router from './router.js'
import router from './router'
import VuePapaParse from 'vue-papa-parse'


// import VueRouter from 'vue-router'
// import vuex from "./vuex";
// import store from "./store"; 
// import {store} from '../store/store'
const app = createApp(App).use(router).use(Antd).use(VuePapaParse);
// app.use(store)
app.mount("#app");
