<template>
<a-layout-header class="header">
    <div class="logo" />
    <a-span style="float:left;" trigger="click">
        <h3>DMS | Data Management System</h3>
    </a-span>
    <a-popover title="">
        <template #content>
            <a @click="this.$root.logout()">Logout</a>
        </template>
        <div style="float:right;">
        <a-span style="margin-left: 5px;">{{ user }}</a-span>
    </div>
    </a-popover>
</a-layout-header>
</template>

<script>
import {
    defineComponent,
    ref
} from "vue";

export default defineComponent({
    name: 'main-page',
    props: {
        user: {
            type: Object,
        },
    },
    components: {
     
    },
    setup() {

        const projMain = ref({})

        return {
            projMain,
        };
    }

    // , mounted() {
    //   // console.log('ttttt')
    //   this.projMain.userEmail = getCurrentInstance().parent.parent.parent.data.email
    //   this.projMain.userName = getCurrentInstance().parent.parent.parent.data.name
    //   this.projMain.userProfilePic = getCurrentInstance().parent.parent.parent.data.profilePic
    //   // console.log(getCurrentInstance())

    // },
})
</script>

<style scoped>
#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /* background: rgba(255, 255, 255, 0.3); */
}

.header {
    background: #fff;
    box-shadow: 0 0px 4px #ffffff;
    margin-bottom: 4px;
    padding: 0 16px;
}

.logo {
    background-image: url("../assets/logo_2 1.png");
    float: left;
    width: 60px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
}
</style> 