<template>
<a-layout-sider v-model:collapsed="collapsed " theme="light" collapsible style="flex:unset!important; " width="256" >
    <div class="logo" />
    <a-menu v-model:selectedKeys="selectedKeys" mode="inline">
        <a-menu-item key="1">
            <HomeOutlined />
            <span>
                <router-link to="/">Home</router-link>
            </span>
        </a-menu-item>
        <a-menu-item key="2">
            <CodeOutlined />
            <span>
                <router-link to="/query-studio">Query Studio</router-link>
            </span>
        </a-menu-item>
        <a-menu-item key="3">
            <FileDoneOutlined />
            <span>
                <router-link to="/report-center">Report Center</router-link>
            </span>
        </a-menu-item>

        <a-menu-item key="4">
            <AppstoreAddOutlined />
            <span>
                <router-link to="/dynamic-download-center">Dynamic Download Center</router-link>
            </span>
        </a-menu-item>

        <a-menu-item key="5">
            <ImportOutlined />
            <span>
                <router-link to="/data-import">Data Import</router-link>
            </span>
        </a-menu-item>

        <a-menu-item key="6">
            <SettingOutlined />
            <span>
                <router-link to="/setting-page">Settings</router-link>
            </span>
        </a-menu-item>

        
        <!-- <a-menu-item key="1">
            <pie-chart-outlined />
            <span>
                <router-link to="/task-management">Task Management</router-link>
            </span>
        </a-menu-item>
        <a-menu-item key="2">
            <desktop-outlined />
            <span>
                <router-link to="/task-monitoring">Task Mornitoring</router-link>
            </span>
        </a-menu-item>
        <a-menu-item key="3">
            <file-done-outlined />
            <span>
                <router-link to="/task-submission">Submission Task</router-link>
            </span>
        </a-menu-item> -->
    </a-menu>

</a-layout-sider>
</template>

<script>
import {
    HomeOutlined,
    CodeOutlined,
    FileDoneOutlined,
    SettingOutlined,
    ImportOutlined,
    AppstoreAddOutlined,
    // DesktopOutlined,
    // FileDoneOutlined
    // close-circle-outlined
} from '@ant-design/icons-vue';
import {
    defineComponent,
    ref
} from 'vue';
export default defineComponent({
    components: {
        HomeOutlined,
        CodeOutlined,
        FileDoneOutlined,
        SettingOutlined,
        ImportOutlined,
        AppstoreAddOutlined,
        // DesktopOutlined,
        // FileDoneOutlined
    },
    data() {
        return {
            collapsed: ref(false),
            selectedKeys: ref(['1']),
        };
    },
});
</script>

<style scoped>
#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

[data-theme='light'] .site-layout .site-layout-background {
    background: #ffffff;
}

:where(.css-dev-only-do-not-override-1ugq3w3).ant-layout .ant-layout-sider {
    flex: unset !important;
    min-width: unset !important;
}
</style>
