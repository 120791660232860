<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Settings</a-divider></a-col>

    </a-row>

<a-row>
    <a-col :span="24">

    <a-spin :spinning="appCurrentSettings.is_page_loading">
       <a-tabs v-model:activeKey="appCurrentSettings.tabActiveKey">
    <a-tab-pane key="1" tab="Users">
        <p>Please input user email here</p>
        <a-select
        v-model:value="appCurrentSettings.current_user_list"
        mode="tags"
        style="width: 100%"
        placeholder="Current users allowed to use the system"
        :options="appCurrentSettings.current_user_list.map(item => ({value:item, label:item}))"
        >
        </a-select>
    <br><br><br>
    <a-button type="primary" @click="updateUserList" style="float:right">Save</a-button>
    </a-tab-pane>
  </a-tabs>

    </a-spin>
    </a-col>
</a-row>
</a-layout-content>


<a-modal v-model:open="appCurrentSettings.sqlCodeModal" title="SQL" @ok="() => {this.appCurrentSettings.sqlCodeModal = false}">
      <p>{{appCurrentSettings.finalQuery}}</p>
</a-modal>

</template>

<script>
import {
    defineComponent,
    ref,
    // reactive
} from 'vue';
import {
    // InboxOutlined,
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

import axios from 'axios';
axios.defaults.withCredentials = true


export default defineComponent({
    components: {
        // InboxOutlined,
    },
    data() {
        const appCurrentSettings = {}
        appCurrentSettings.is_page_loading = false
        appCurrentSettings.current_user_list = []
        appCurrentSettings.tabActiveKey = ref('1')
        

        return {
            appCurrentSettings:appCurrentSettings,
            unparsedResults:null,
        }
    },
    watch: {
    unparsedResults (current) {
      if (current) {
        console.log(current)
      }
    }
  },
    methods:{
        renderEachTab(){
            if(this.appCurrentSettings.tabActiveKey.toString() == '1'){
                this.getUserList()
            }
        },
       getUserList(){
        this.appCurrentSettings.is_page_loading = true
        axios
            .get(this.$root.app_api_endpoint+'/get_user_list')
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.current_user_list = response.data.data
                    this.appCurrentSettings.is_page_loading = false
                }else{
                    message.error('Error checking column name')
                    this.appCurrentSettings.is_page_loading = false
                }
            })
       },
       updateUserList(){
        this.appCurrentSettings.is_page_loading = true
        let data = {
                user_list: this.appCurrentSettings.current_user_list,
                };
            axios
            .post(this.$root.app_api_endpoint+'/update_user_list',data)
            .then((response) => {
                if (response.data.success == true) {
                    message.success('Done')
                    this.appCurrentSettings.is_page_loading = false
                }else{
                    message.error('Error, create table error');
                    this.appCurrentSettings.is_page_loading = false
                }
            })
       }
    },
    

    created() {
        this.renderEachTab()
    },
    mounted(){
        // CodeMirror.fromTextArea(document.getElementById('main-code-editor'),{
        //     lineNumbers:true
        // })
    }

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
}
</style>