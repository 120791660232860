<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Data Import</a-divider></a-col>

    </a-row>

<a-row>
    <a-col :span="24">

    <a-spin :spinning="appCurrentSettings.is_page_loading">
        <a-tabs v-model:activeKey="appCurrentSettings.tabActiveKey">
    <a-tab-pane key="1">
      <template #tab>
        <span>
          <apple-outlined />
          Import CSV
        </span>
      </template>
      <p>Destination Table Name &nbsp;&nbsp;<a-span style="color:#989898"><i> Allow: (a-z, 0-9, _)</i></a-span></p>
        <a-input 
        v-model:value="appCurrentSettings.destination_table_name" 
        placeholder="Please set name for destination table"
        @change="checkIfTableExists"
         /><br>
         <p style="color:red" v-if="appCurrentSettings.shows_duplicated_table_warning">This table already exists. Uploading will overwrite the data in table.</p>
        <br><br>
        <p>CSV File Upload&nbsp;&nbsp;<a-span style="color:#989898"><i> Allow Column with: (a-z, 0-9, _)</i></a-span></p>
        <div>
        <a-upload-dragger
        v-model:fileList="appCurrentSettings.upload_file_list"
        name="file"
        :multiple="false"
        :beforeUpload="handleFileSelect"
        maxCount="1"
        accept=".csv,.CSV"
    >
        <p class="ant-upload-drag-icon">
        <InboxOutlined />
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload</p>
        <p class="ant-upload-hint">
        Support for a single upload. Please delete current selected file first if want to upload a new file
        </p>
    </a-upload-dragger>
        </div>
<br><br>
<a-button type="primary" @click="validateCsv" style="float:right">Import</a-button>


    </a-tab-pane>
    <a-tab-pane key="2">
      <template #tab>
        <span>
          <android-outlined />
          Import Google Sheet
        </span>
      </template>
      <p>Destination Table Name &nbsp;&nbsp;<a-span style="color:#989898"><i> Allow: (a-z, 0-9, _)</i></a-span></p>
        <a-input 
        v-model:value="appCurrentSettings.destination_table_name" 
        placeholder="Please set name for destination table"
        @change="checkIfTableExists"
         /><br>
         <p style="color:red" v-if="appCurrentSettings.shows_duplicated_table_warning">This table already exists. Uploading will overwrite the data in table.</p>
        <br><br>
        <p style="color:black"><i>Please grant access to: <u>robotic-almanac-414609@appspot.gserviceaccount.com</u></i></p>
        <p>Google Sheet URL&nbsp;&nbsp;<a-span style="color:#989898"><i> Allow Column with: (a-z, 0-9, _)</i></a-span></p>
        <a-input 
        v-model:value="appCurrentSettings.google_sheet_url" 
        placeholder="Please input Google Sheet URL"
        @change="getGsheetTableTabList"
         /><br><br><br>
         <p>Google Sheet Tab Name</p>
         <a-select
            ref="select"
            v-model:value="appCurrentSettings.google_sheet_tab_name_selected"
            style="width: 200px"
            :options="appCurrentSettings.google_sheet_tab_name"
            >
            </a-select>
        
        <br><br>
        <a-button type="primary" @click="getGsheetColumnListAndCreateTable" style="float:right" :disabled="appCurrentSettings.google_sheet_tab_name_selected == null ? true : false">Import</a-button>
    </a-tab-pane>
  </a-tabs>
        

    </a-spin>
    </a-col>
</a-row>
</a-layout-content>


<a-modal v-model:open="appCurrentSettings.sqlCodeModal" title="SQL" @ok="() => {this.appCurrentSettings.sqlCodeModal = false}">
      <p>{{appCurrentSettings.finalQuery}}</p>
</a-modal>

</template>

<script>
import {
    defineComponent,
    ref,
    // reactive
} from 'vue';
import {
    InboxOutlined,
} from '@ant-design/icons-vue';
import { message,notification } from 'ant-design-vue';
import md5 from 'md5';

import axios from 'axios';
axios.defaults.withCredentials = true


export default defineComponent({
    components: {
        InboxOutlined,
    },
    data() {
        const appCurrentSettings = {}
        appCurrentSettings.is_page_loading = false
        appCurrentSettings.destination_table_name = ''
        appCurrentSettings.upload_file_list = []
        appCurrentSettings.csv_column_list = []
        appCurrentSettings.table_list = []
        appCurrentSettings.shows_duplicated_table_warning = false
        appCurrentSettings.google_sheet_url = ''
        appCurrentSettings.google_sheet_tab_name = []
        appCurrentSettings.is_google_sheet_url_settings_ok = false
        appCurrentSettings.google_sheet_tab_name_selected = null
        appCurrentSettings.google_sheet_col_list = []
        appCurrentSettings.tabActiveKey = ref('1')
        

        return {
            appCurrentSettings:appCurrentSettings,
            unparsedResults:null,
        }
    },
    watch: {
    unparsedResults (current) {
      if (current) {
        console.log(current)
      }
    }
  },
    methods:{
        createBqTableFromGsheet(){
            let sheet_id = ''
            try{
                sheet_id = this.appCurrentSettings.google_sheet_url.split('/spreadsheets/d/')[1].split('/')[0]
            }catch{
                sheet_id = ''
            }
            let data = {
                sheet_id: sheet_id,
                tab_name: this.appCurrentSettings.google_sheet_tab_name_selected,
                table_name: this.appCurrentSettings.destination_table_name,
                column_list : this.appCurrentSettings.google_sheet_col_list
                };
            axios
            .post(this.$root.app_api_endpoint+'/create_bq_table_from_gsheet',data)
            .then((response) => {
                if (response.data.success == true) {
                    notification['success']({
                        message: 'Create Table Success!',
                        duration:0,
                        description:
                        `Create Table "${this.appCurrentSettings.destination_table_name}" Success!`
                    });
                    this.appCurrentSettings.is_page_loading = false
                }else{
                    message.error('Error, create table error');
                    this.appCurrentSettings.is_page_loading = false
                }
            })
        },
        getGsheetColumnListAndCreateTable(){
            let sheet_id = ''
            try{
                sheet_id = this.appCurrentSettings.google_sheet_url.split('/spreadsheets/d/')[1].split('/')[0]
            }catch{
                sheet_id = ''
            }
            if(sheet_id == ''){
                // alert('Invalid URL pattern')
                message.error('Invalid URL pattern')
                return false
            }

            if(this.appCurrentSettings.google_sheet_tab_name_selected == null || this.appCurrentSettings.google_sheet_tab_name_selected == ''){
                alert('Please select Google Sheet Tab')
                return false
            }
            if(!this.appCurrentSettings.is_google_sheet_url_settings_ok){
                alert('Please recheck URL, access,  and column name')
                return false
            }
            if(!this.precheckCsvTables()){
                return false
            }
            this.appCurrentSettings.is_page_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/get_gsheet_col_list?sheet_id='+sheet_id+'&tab_name='+this.appCurrentSettings.google_sheet_tab_name_selected)
            .then((response) => {
                if (response.data.success == true) {
                    if(this.precheckDuplicatedColumns(response.data.data)){
                        this.appCurrentSettings.google_sheet_col_list = response.data.data
                        this.createBqTableFromGsheet()
                    }else{
                        this.appCurrentSettings.is_page_loading = false
                        return false
                    }
                }else{
                    message.error('Error checking column name')
                    this.appCurrentSettings.is_page_loading = false
                }
            })
        },
        getGsheetTableTabList(){
            let sheet_id = ''
            try{
                sheet_id = this.appCurrentSettings.google_sheet_url.split('/spreadsheets/d/')[1].split('/')[0]
            }catch{
                sheet_id = ''
            }
            if(sheet_id == ''){
                // alert('Invalid URL pattern')
                message.error('Invalid URL pattern')
                return false
            }
            if(this.precheckCsvTables()){
                this.appCurrentSettings.is_page_loading = true
                axios
                .get(this.$root.app_api_endpoint+'/get_gsheet_tab_list?sheet_id='+sheet_id)
                .then((response) => {
                    if (response.data.success == true) {
                        this.appCurrentSettings.google_sheet_tab_name = response.data.data.map(item => ({ value:item, label: item }))
                        this.appCurrentSettings.is_page_loading = false
                        this.appCurrentSettings.is_google_sheet_url_settings_ok = true
                    }else{
                        alert('Cannot access, please check URL and access')
                        this.appCurrentSettings.is_page_loading = false
                        this.appCurrentSettings.is_google_sheet_url_settings_ok = false
                    }
                })
            }
        }
        ,precheckCsvTables(){
            let regex = /^[a-z0-9_]*$/;
            let test_name = regex.test(this.appCurrentSettings.destination_table_name)
            if(this.appCurrentSettings.destination_table_name == null || !test_name || this.appCurrentSettings.destination_table_name == '' ){
                alert('name can only contains a-z lowercase, underscore, or numbers')
                return false
            }
            return true
        },
        precheckDuplicatedColumns(array_list){
            if(array_list == null || array_list.length == 0){
                alert('Please recheck your column first!')
                return false
            }
            if(new Set(array_list).size !== array_list.length){
                alert('Duplicated column name in the file!')
                return false
            }
            let incorrect_header_column = []
            for (let i = 0; i < array_list.length; i++) {
                let regex = /^[a-z0-9_]*$/;
                if(!regex.test(array_list[i]) || array_list[i]==''){
                    incorrect_header_column.push(array_list[i])
                }
            }
            if(incorrect_header_column.length > 0){
                notification['error']({
                    message: 'Incorrect Column Name',
                    description:
                    `Some columns has incorrect format (allow: a-z,0-9,_)\r\n
                       Please fix :`+incorrect_header_column.join(','),
                });
                return false
            }
                
            
            return true
            
        },
        readFileAsText (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                const decoder = new TextDecoder();
                const text = decoder.decode(reader.result);
                resolve(text);
                };
                reader.onerror = () => {
                reject(new Error('Error reading file.'));
                };
                reader.readAsArrayBuffer(file);
            });
            },
         async validateCsv(){
            let config = {
            delimiter: "",	// auto-detect
            newline: "",	// auto-detect
            quoteChar: '"',
            escapeChar: '"',
            header: false,
            transformHeader: undefined,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            step: undefined,
            complete: undefined,
            error: undefined,
            download: false,
            downloadRequestHeaders: undefined,
            downloadRequestBody: undefined,
            skipEmptyLines: true,
            chunk: undefined,
            chunkSize: undefined,
            fastMode: undefined,
            beforeFirstChunk: undefined,
            withCredentials: undefined,
            transform: undefined,
            delimitersToGuess: [',', '\t', '|', ';'],
            skipFirstNLines: 0
        }
            if(this.appCurrentSettings.upload_file_list.length == 0){
                alert('Please upload a file')
                return false
            }
            let file =  { ...this.appCurrentSettings.upload_file_list[0] };
            let csvResult = await this.$papa.parse(await this.readFileAsText(file.originFileObj), config)
            if(this.precheckCsvTables() && this.precheckDuplicatedColumns(csvResult.data[0])){
                this.appCurrentSettings.is_page_loading = true
                this.appCurrentSettings.csv_column_list = csvResult.data[0]
                console.log(csvResult)
                let file_name = md5(file.originFileObj) + '.csv'

                let signedUrl = await this.getUploadingFileUrl(file_name)
                console.log(await signedUrl)
                // let status = await this.uploadFileToGCS(signedUrl.data.data, file.originFileObj)
                // console.log(status)

            }
            
            
            

        },

        async uploadFileToGCS(signedUrl, file,filePath) {
            try {
                console.log(file.type)
                const response = await axios.put(signedUrl,file, {
                   
                headers: {
                    'content-type': file.type,
                },
                withCredentials: false,
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload Progress: ${progress}%`);
                    message.success('Finish Uploading File')
                    this.createBqTable(filePath, this.appCurrentSettings.csv_column_list, this.appCurrentSettings.destination_table_name)
                },
                });

                console.log('Upload successful:', response);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },

       
        async getUploadingFileUrl(filePath){
            return await axios
            .get(this.$root.app_api_endpoint+'/get_upload_url?file_path='+filePath)
            .then(async (response) => {
                if (response.data.success == true) {
                    console.log('get url success')
                    let a = await this.uploadFileToGCS(response.data.data, this.appCurrentSettings.upload_file_list[0]['originFileObj'],filePath)
                    console.log(a)
                }
        })
        },
        handleFileSelect(){
            return false
        },

        createBqTable(file_name, column_list, table_name){
            let data = {
                file_name: file_name,
                column_list: column_list,
                table_name: table_name
                };
            axios
            .post(this.$root.app_api_endpoint+'/create_bq_table_from_csv',data)
            .then((response) => {
                if (response.data.success == true) {
                    notification['success']({
                        message: 'Create Table Success!',
                        duration:0,
                        description:
                        `Create Table "${this.appCurrentSettings.destination_table_name}" Success!`
                    });
                    this.appCurrentSettings.is_page_loading = false
                }else{
                    message.error('Error, create table error');
                    this.appCurrentSettings.is_page_loading = false
                }
            })
        },
        loadTablesList(){
            this.appCurrentSettings.is_page_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_tables?dataset_id=primary_dw')
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.table_list = response.data.data.map(item => item.name);
                    this.appCurrentSettings.is_page_loading = false
                }
            })
        },
        checkIfTableExists(){
            this.appCurrentSettings.shows_duplicated_table_warning = false
            console.log(this.appCurrentSettings.table_list)
            if(this.appCurrentSettings.table_list.includes(this.appCurrentSettings.destination_table_name)){
                this.appCurrentSettings.shows_duplicated_table_warning = true
            }
        }

        
        
    },
    

    created() {
        this.loadTablesList()
    },
    mounted(){
        // CodeMirror.fromTextArea(document.getElementById('main-code-editor'),{
        //     lineNumbers:true
        // })
    }

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
}
</style>