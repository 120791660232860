<template>
<a-layout-content :style="{ background: '#fff', padding: '24px', margin:'16px 0',  minHeight: '1080px' }">
    <a-row>
    <a-col :span="24"><a-divider orientation="left">Query Studio</a-divider></a-col>

    </a-row>

<a-row>

    <a-col :span="6">
        <div style="width:90%">
            DW Tables:
            
             <a-input-group compact>
             <a-select v-model:value="appCurrentSettings.table_dataset" style="width: 30%" @change="loadTablesList">
                <a-select-option value="platform_application_data">RAW</a-select-option>
                <a-select-option value="primary_dw" selected>DWD</a-select-option>
            </a-select>
            <a-input v-model:value="appCurrentSettings.tableKeywordSearching" style="width: 70%" @change="filteredTableList"/>
            </a-input-group><br>
            <div style="margin-bottom:10px;">
    <div
    style="
            width: 90%; 
            white-space: initial;
            word-wrap: break-word;
            float:left;
            font-size:0.9em;
            font-weight:200;"
            
    
    >Dataset: {{appCurrentSettings.table_dataset}}</div>
            </div>
    <a-divider ></a-divider>
    <a-layout-sider
     theme="light"
     width="100%"
     class="table-list"
     :style="{height:'50vh','min-width!important': '100%','max-width': '100%!important'}"
    >
         <a-list item-layout="horizontal" :data-source="appCurrentSettings.table_list_filtered" style="min-width: 100%" :loading="appCurrentSettings.is_table_list_loading">
            <template #renderItem="{ item }">
            <a-list-item @click="loadTablesSchema(item.name)" class="render-table-list-result">
                {{ item.name }}
            </a-list-item>
            </template>
        </a-list>
    </a-layout-sider>
    <a-divider ></a-divider>
    Table Schema:<br>
    <div style="margin-top:10px;">
    <div
    style="
            width: 90%; 
            white-space: initial;
            word-wrap: break-word;
            float:left;
            font-size:0.9em;
            font-weight:200;"
            
    
    >{{appCurrentSettings.table_selected_display}}</div> <a><CopyOutlined style="float:right" v-if="appCurrentSettings.table_selected_display != ''" @click="copyText(appCurrentSettings.table_selected_display)"/></a></div><br>
    <a-button style="margin-top:20px;"   size="small" v-if="appCurrentSettings.table_selected_display != ''" @click="getTablePreview">Preview</a-button><br><br>
    <a-layout-sider
     theme="light"
     width="100%"
     class="table-list"
     :style="{height:'50vh', 'min-width!important': '100%','max-width': '100%!important'}"
    >
         <a-list item-layout="horizontal" :data-source="appCurrentSettings.table_schema.columns" style="min-width: 100%" :loading="appCurrentSettings.is_table_schema_loading">
            <template #renderItem="{ item }">
            <a-list-item class="render-table-schema-result">{{ item[0] }}<span style="float:right;"><i>{{ item[1] }}</i></span></a-list-item>
            </template>
        </a-list>
    </a-layout-sider>

    </div>
    </a-col>
    <a-col :span="18">
        <a-row>
            <a-col :span="24">
                <div style="float:left; width:100%; display:inline-flex!important; margin-bottom:10px;" class="top-tab">
                <div style="float:left; width:70%">
                    
                    <a-button type="link" @click="openFileBrowser" style="margin-right:20px;padding:0px;"><FileTextOutlined /> Open</a-button>
                    <a-button type="link" @click="openSaveModal" style="margin-right:20px;padding:0px;"><SaveOutlined /> Save</a-button>
                    <a-button type="link" @click="openScheduleModal" style="margin-right:20px;padding:0px;" :disabled="appCurrentSettings.currentTask_type == 'ADHOC' ? false : true"><ToTopOutlined /> Create Schedule</a-button>
                    <a-button type="link" @click="() => {this.renderDynamicParameter(); this.appCurrentSettings.naming_file_modal_dynamic = true}" style="margin-right:20px;padding:0px;" :disabled="appCurrentSettings.currentTask_type == 'ADHOC' ? false : true"><ToTopOutlined /> Create Dynamic Download</a-button>
                    <span v-if="appCurrentSettings.saving_file"><SyncOutlined spin /></span>
                    
                </div>
                <div style="float:right; width:30%">
                    <span style="margin-left:20px;float:right;">File: 
                        <span v-if="appCurrentSettings.file_id != ''">{{appCurrentSettings.file_id.split('_')[3].split('.txt')[0]}} </span>
                        <span v-else><i>Untitled</i></span>
                        <span v-if="appCurrentSettings.is_edited_without_save">*</span>
                        <span v-if="appCurrentSettings.file_id != ''" >&nbsp;&nbsp;&nbsp;<a @click="closeCurrentFile"><CloseCircleOutlined style="font-size:1em;" /></a></span>
                        
                    </span>
                </div>
                </div>

            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <codeEditor :key="componentKey" ref="childComponent" :codeValue="appCurrentSettings.current_query_content" @updateMessage="updateMessageInParent" style="min-width:100%!important"/>
            </a-col>
        </a-row>

        <a-row>
            <a-col :span="24">
                <div style="float:right;width:100%;margin-top:20px;margin-bottom:20px;">
                    <a-button style="float:right" type="primary" @click="submitRunData('PREVIEW')">Run</a-button>
                    <a-button style="float:right;margin-right:20px;" @click="submitRunData('FULL_ADHOC_RUN')">Run with Download</a-button>
                    
                </div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                    <span id="queryTablePreviewInfo" style="display:none"><a-alert type="info" message="This is table preview" banner /></span>
                    <span id="queryAlertInfo" style="display:none"><a-alert type="error" :message="appCurrentSettings.queryErrorMessage" banner /></span>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-table
                :columns="appCurrentSettings.current_table_column"
                
                :data-source="appCurrentSettings.current_table_data_source"
                
                :loading="appCurrentSettings.is_loading_result_preview"
                :scroll="{ x: 200 }"
                size="small"
                class="display-result-table"
                
            >
                <template #bodyCell="{ column, text }">
                <template v-if="column.dataIndex === 'name'">{{ text.first }} {{ text.last }}</template>
                </template>
            </a-table>
            </a-col>
        </a-row>
        
            
    </a-col>
</a-row>

</a-layout-content>

<a-drawer
    v-model:open="appCurrentSettings.is_file_browser_open"
    class="custom-class"
    title="Open Query"
    placement="right"
    @after-open-change="afterOpenChange"
  >

  <!-- saved_query_schedule_task_list
saved_query_schedule_task_list_loading -->


    <p><b>Saved Query</b>&nbsp;&nbsp;&nbsp;<a-button type="link" :disabled="appCurrentSettings.saved_query_file_list_loading" @click="() => {this.loadFileList()}"><sync-outlined/></a-button></p>
        <a-list item-layout="horizontal" :data-source="appCurrentSettings.saved_query_file_list" style="min-width: 100%" :loading="appCurrentSettings.saved_query_file_list_loading">
            <template #renderItem="{ item }">
            <a-list-item><a @click="openSavedFile(item.split('.txt')[0],'ADHOC')"><FileTextOutlined /> &nbsp;&nbsp;{{ item.split('_')[3].split('.txt')[0] }}</a>    <a-span style="float:right;color:#989898!important;">
                                                                                                                                                                    <a-popconfirm
                                                                                                                                                                    title="Are you sure delete this task?"
                                                                                                                                                                    ok-text="Yes"
                                                                                                                                                                    cancel-text="No"
                                                                                                                                                                    @confirm="deleteFileObject(item,'ADHOC')"
                                                                                                                                                                >
                                                                                                                                                                    <a style="color:#989898!important;"><DeleteOutlined /></a>
                                                                                                                                                                    </a-popconfirm>
                                                                                                                                                                    </a-span>
                                                                                                                                                                    </a-list-item>
            </template>
        </a-list><br><br>
    <p><b>Scheduled Query</b>&nbsp;&nbsp;&nbsp;<a-button type="link" :disabled="appCurrentSettings.saved_query_dynamic_file_list_loading" @click="() => {this.loadScheduleTask()}"><sync-outlined/></a-button></p>
    <a-list item-layout="horizontal" :data-source="appCurrentSettings.saved_query_schedule_task_list" style="min-width: 100%" :loading="appCurrentSettings.saved_query_schedule_task_list_loading">
            <template #renderItem="{ item }">
            <a-list-item><a @click="openScheduledTask(item.display_name)"><FileTextOutlined /> &nbsp;&nbsp;{{ item.display_name.split('_')[3] }}</a><a-span style="float:right;color:#989898!important;">
                                                                                                                                                                    <a-popconfirm
                                                                                                                                                                    title="Are you sure delete this task?"
                                                                                                                                                                    ok-text="Yes"
                                                                                                                                                                    cancel-text="No"
                                                                                                                                                                    @confirm="deleteScheduleTask(item.id)"
                                                                                                                                                                >
                                                                                                                                                                    <a style="color:#989898!important;"><DeleteOutlined /></a>
                                                                                                                                                                    </a-popconfirm>
                                                                                                                                                                    </a-span>
                                                                                                                                                                    </a-list-item>
            </template>
        </a-list>
    
    <br><br>
    <p><b>Dynamic Query</b>&nbsp;&nbsp;&nbsp;<a-button type="link" :disabled="appCurrentSettings.saved_query_schedule_task_list_loading" @click="() => {this.loadDynamicFileList()}"><sync-outlined/></a-button></p>
    <a-list item-layout="horizontal" :data-source="appCurrentSettings.saved_query_dynamic_file_list" style="min-width: 100%" :loading="appCurrentSettings.saved_query_dynamic_file_list_loading">
            <template #renderItem="{ item }">
            <a-list-item><a @click="openSavedFile(item.split('.txt')[0],'DYNAMIC')"><FileTextOutlined /> &nbsp;&nbsp;{{ item.split('_')[3].split('.txt')[0] }}</a><a-span style="float:right;color:#989898!important;">
                                                                                                                                                                    <a-popconfirm
                                                                                                                                                                    title="Are you sure delete this task?"
                                                                                                                                                                    ok-text="Yes"
                                                                                                                                                                    cancel-text="No"
                                                                                                                                                                    @confirm="deleteFileObject(item,'DYNAMIC')"
                                                                                                                                                                >
                                                                                                                                                                    <a style="color:#989898!important;"><DeleteOutlined /></a>
                                                                                                                                                                    </a-popconfirm>
                                                                                                                                                                    </a-span>
                                                                                                                                                                    </a-list-item>
            </template>
        </a-list>

    
    
  </a-drawer>
  <a-modal v-model:open="appCurrentSettings.naming_file_modal" title="Set Query Name" @ok="saveQueryFile" :confirm-loading="appCurrentSettings.saving_file" @cancel="() => {
    this.appCurrentSettings.current_friendly_name = ''
    }">
    <p>Please set query name:</p>
    <p>Name can contains: characters, numbers, and dash (-)</p>
      <a-input v-model:value="appCurrentSettings.current_friendly_name" placeholder="Name" />
      <!-- saveQueryFile() -->
    </a-modal>


<a-modal v-model:open="appCurrentSettings.naming_file_modal_schedule" title="Create New or confirm update Schedule Task" @ok="createTaskSchedule" :confirm-loading="appCurrentSettings.saving_file" @cancel="() => {
    if(this.file_id==''){
        this.appCurrentSettings.currentScheduleScheduleCadence = 'every day'
        this.appCurrentSettings.current_friendly_name = ''
    }
    
    }">
    <p>Please set query name schedule:</p>
    <p>Name can contains: characters, numbers, and dash (-)</p>
      <a-input v-model:value="appCurrentSettings.current_friendly_name" placeholder="Name" :disabled="appCurrentSettings.file_id !='' ? true : false" />

      <br><br>Select Cadence:<br>
      <a-select v-model:value="appCurrentSettings.currentScheduleScheduleCadence" style="width: 30%" @change="reGenerateDayList" @click="reGenerateDayList">
                <a-select-option value="every day" >Every day</a-select-option>
                <a-select-option value="every week" >Every Week</a-select-option>
                <a-select-option value="every month" >Every Month</a-select-option>
            </a-select>
    <br><br>
    <a-row>

        <a-col :span="6">
            Select Run Time:<br>
                
            <a-time-picker v-model:value="appCurrentSettings.currentScheduleScheduleCadenceTime" use24-hours format="HH:mm" />
        </a-col>
        <a-col :span="18" style="padding-left:20px;">
            Select Run Day:<br>
            <a-tree-select
            v-model:value="appCurrentSettings.selectedScheduleDayListValue"
            style="width: 100%"
            :tree-data="appCurrentSettings.scheduleDayList"
            tree-checkable
            allow-clear
            :show-checked-strategy="SHOW_PARENT"
            placeholder="Please select"
            tree-node-filter-prop="label"
            :disabled="appCurrentSettings.currentScheduleScheduleCadence =='every day' ? true : false"
        />
        </a-col>
    </a-row><br>
    </a-modal>



<!-- MODAL FOR DYNAMIC DOWNLOAD -->
<a-modal 
v-model:open="appCurrentSettings.naming_file_modal_dynamic" 
title="Create New or confirm update Dynamic Task" @ok="saveDynamicQueryFile" 
:confirm-loading="appCurrentSettings.saving_file" 
@cancel="() => {
    if(this.file_id==''){
        
        this.appCurrentSettings.current_friendly_name = ''
    }
    
    }" 
 :width=1000    
>
    <p>Please set query name dynamic download:</p>
    <p>Name can contains: characters, numbers, and dash (-)</p>
      <a-input v-model:value="appCurrentSettings.current_friendly_name" placeholder="Name" :disabled="appCurrentSettings.file_id !='' ? true : false" />

    <br><br>
    <a-row >

        <a-col :span="24">
           <a-form
    ref="formRef"
    name="dynamic_form_nest_item"
    :model="dynamicValidateForm"
    @finish="onFinish"
    
  >
    
      <a-form-item>
      <a-row style="width:100%;margin-bottom:10px;">
        <a-col :span="4" style="font-weight:700">
            Parameter Name
        </a-col>
        <a-col :span="4" style="padding-left:10px;font-weight:700">
            Type
        </a-col>
        <a-col :span="8" style="padding-left:10px;font-weight:700">
            Possible Value
        </a-col>
        <a-col :span="8" style="padding-left:10px;font-weight:700">
            Default Value
        </a-col>
      </a-row>
    
    <a-row
      v-for="(k) in appCurrentSettings.currentDynamicParamString.params"
      :key="k.id"
      style="display: flex; margin-bottom: 16px; width:100%"
      align="baseline"
      wrap
      class="parameter-space"

    >
    <a-col :span=24>
      <a-row style="margin-bottom:10px;">
        <a-col :span="4">
            {{k.param_name}}
        </a-col>
        <a-col :span="4"  style="padding-left:10px">
            <a-select
            ref="select"
            v-model:value="k.param_type"
            @focus="focus"
            @change="() => {
                if(k.param_type == 'TEXT'){k.possible_value = ''; k.default_value = ''}
                else if(k.param_type == 'DATE'){k.possible_value = null; k.default_value = null}
                else if(k.param_type == 'MULTIPLE_SELECT'){k.possible_value = []; k.default_value = []}
                else if(k.param_type == 'SINGLE_SELECT'){k.possible_value = []; k.default_value = null}
             
                }"
            >
                <a-select-option value="TEXT">Text</a-select-option>
                <a-select-option value="DATE">Date</a-select-option>
                <a-select-option value="MULTIPLE_SELECT">Multiple Select</a-select-option>
                <a-select-option value="SINGLE_SELECT">Single Select</a-select-option>
            </a-select>
        </a-col>
        <a-col :span="8"  style="padding-left:10px">
             
            <a-select
                v-if="k.param_type=='MULTIPLE_SELECT'"
                v-model:value="k.possible_value"
                mode="tags"
                :size="size"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
            <a-select
                v-if="k.param_type=='SINGLE_SELECT'"
                v-model:value="k.possible_value"
                mode="tags"
                :size="size"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
            
        </a-col>
        <a-col :span="8" style="padding-left:10px">
            <a-input v-if="k.param_type=='TEXT'" v-model:value="k.default_value" placeholder="Possible Value" />
            <a-date-picker v-if="k.param_type=='DATE'" v-model:value="k.default_value" valueFormat="YYYY-MM-DD"/>
            <a-select
                v-if="k.param_type=='MULTIPLE_SELECT'"
                v-model:value="k.default_value"
                mode="multiple"
                :size="size"
                :options="k.possible_value.map(value => ({ value: value, label: value }))"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
            <a-select
                v-if="k.param_type=='SINGLE_SELECT'"
                v-model:value="k.default_value"
                :options="k.possible_value.map(value => ({ value: value, label: value }))"
                mode="options"
                :size="size"
                placeholder="Please select"
                style="width:80%"
            ></a-select>
        </a-col>
      </a-row>
      </a-col>
    </a-row>
      </a-form-item>
    
    
    
  </a-form>



        </a-col>
    </a-row><br>
    </a-modal>


</template>

<script>
import {
    defineComponent,
    reactive,
    ref,
} from 'vue';
import {
    SaveOutlined,
    FileTextOutlined,
    ToTopOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    CopyOutlined,
    DeleteOutlined,
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
// import CodeMirror from 'vue-codemirror6';
// import { sql } from "@codemirror/lang-sql";
import codeEditor from '@/components/CodeEditor.vue'
import dayjs from 'dayjs';

import axios from 'axios';
axios.defaults.withCredentials = true
// import modal_create_task from '@/components/MgmtModalSuccess.vue'
// import draggable from 'vuedraggable';


export default defineComponent({
    components: {
        FileTextOutlined,
        SaveOutlined,
        ToTopOutlined,
        // CodeMirror,
        codeEditor,
        CloseCircleOutlined,
        SyncOutlined,
        CopyOutlined,
        DeleteOutlined,
    },
    data() {
        const defaultFormState = reactive({
            taskName: '',
            submissionFormFormat: {
                data: [],
            },
            frequency: {},
            countryPIC: [],
            taskRegion: [],
        });
        const orderApiRefreshStatus = {}
        orderApiRefreshStatus['lazada_spu'] = null
        orderApiRefreshStatus['shopee_spu'] = null
        orderApiRefreshStatus['tiktok_spu'] = null
        orderApiRefreshStatus['line_spu'] = null
        orderApiRefreshStatus['woocommerce_spu'] = null

        const appCurrentSettings = {}
        appCurrentSettings['table_dataset'] = 'primary_dw'
        appCurrentSettings['table_list'] = []
        appCurrentSettings['table_list_filtered'] = []
        appCurrentSettings['table_schema'] = []
        appCurrentSettings['table_selected_display'] = ''
        appCurrentSettings['is_open_saved_file'] = false 
        appCurrentSettings['file_id'] = ''
        appCurrentSettings['is_edited_without_save'] = false
        appCurrentSettings['is_file_browser_open'] = false
        appCurrentSettings['current_friendly_name'] = ''
        appCurrentSettings['current_query_content'] = ''
        appCurrentSettings['saved_query_file_list'] = []
        appCurrentSettings['saved_query_dynamic_file_list'] = []
        appCurrentSettings.saved_query_dynamic_file_list_loading = false
        appCurrentSettings['current_running_job_id'] = ''
        appCurrentSettings['current_table_data_source'] = []
        appCurrentSettings['current_table_column'] = []
        appCurrentSettings['is_loading_result_preview'] = false
        appCurrentSettings.saved_query_schedule_task_list = []
        appCurrentSettings.saved_query_schedule_task_list_loading = false
        appCurrentSettings.currentScheduleConfigId = ''
        appCurrentSettings.currentScheduleScheduleCadence = 'every day'
        appCurrentSettings.currentTask_type = 'ADHOC'
        appCurrentSettings.is_enable_create_schedule = true
        appCurrentSettings.update_schedule_task_modal = false
        appCurrentSettings.scheduleDayList = [];
        appCurrentSettings.selectedScheduleDayListValue = []
        appCurrentSettings.currentScheduleScheduleCadenceTime = ''
        appCurrentSettings.currentScheduleScheduleCadenceString = ''
        appCurrentSettings.currentDynamicParamString = {'params':{},'query':''}
        appCurrentSettings.naming_file_modal_dynamic = false

        

        const value = 'select * from table_a'
        // const lang = sql();

        const view = ref();
    const handleReady = (payload) => {
      view.value = payload.view;
      view.value.dispatch({ selection: { anchor: 0, head: 0 } });
    };

        return {
            drag: false,
            formState: reactive({
                ...defaultFormState
            }),
            iconLoading: false,
            successModal: false,
            submitResponse: '',
            failedModal: false,
            orderApiRefreshStatus: orderApiRefreshStatus,
            appCurrentSettings:appCurrentSettings,
            value:value,
            // lang:lang,
            linter:null,
            handleReady:handleReady,
            childComponent:ref({}),
            componentKey: 0,
        }
    },
    methods:{
        deleteFileObject(objId,task_type){
            if(task_type == 'ADHOC'){
                this.appCurrentSettings.saved_query_file_list_loading = true
            }else if(task_type == 'DYNAMIC'){
                this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            }
            axios
            .get(this.$root.app_api_endpoint+'/delete_file_object?object_name='+objId+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    if(task_type == 'ADHOC'){
                        this.appCurrentSettings.saved_query_file_list_loading = false
                        this.loadFileList()
                    }else if(task_type == 'DYNAMIC'){
                        this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                        this.loadDynamicFileList()
                    }
                }
            })
        },
        deleteScheduleTask(objId){
            this.appCurrentSettings.saved_query_schedule_task_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/delete_schedule?config_id='+objId+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_schedule_task_list_loading = false
                    this.loadScheduleTask()
                }
            })
        },
        getTablePreview(){
            // alert(this.$root.app_api_endpoint+'/list_tables?dataset_id='+this.appCurrentSettings.table_dataset)
            this.appCurrentSettings.is_loading_result_preview = true
            axios
            .get(this.$root.app_api_endpoint+'/get_table_preview?table_id='+this.appCurrentSettings.table_selected_display+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.is_loading_result_preview = false
                    document.getElementById('queryTablePreviewInfo').style.display = 'unset'
                    document.getElementById('queryAlertInfo').style.display = 'none'

                    this.appCurrentSettings.current_table_data_source = []
                    this.appCurrentSettings.current_table_column = []
                    this.appCurrentSettings.current_table_data_source = response.data.data.result.map((item, index) => ({
                            key: index.toString(),
                            ...item
                            }));
                        
                    if(this.appCurrentSettings.current_table_data_source.length > 0){
                            for (let [key_] of Object.entries(this.appCurrentSettings.current_table_data_source[0])) {
                            if(key_=='key'){
                                continue
                            }
                                this.appCurrentSettings.current_table_column.push({'title':key_,'dataIndex':key_, 'key':key_})
                            }
                    }
                }
            })
        },
        async copyText(textValue) {
            try {
            await navigator.clipboard.writeText(textValue);
            message.success('Copied')
            } catch($e) {
            message.error('Cannot Copy')
            }
        },
        saveDynamicQueryFile(){
            let regex = /^[a-zA-Z0-9-]*$/;
            let test_name = regex.test(this.appCurrentSettings.current_friendly_name)
            let file_id = null
            if(!test_name || this.appCurrentSettings.current_friendly_name == '' ){
                alert('name can contains A-Z, dash, or space')
                return false
            }
            if(this.appCurrentSettings['file_id'] == ''){
                file_id = this.generateNewFileId('DYNAMIC',this.appCurrentSettings.current_friendly_name)
            }else{
                file_id = this.appCurrentSettings['file_id']
            }
            
            this.appCurrentSettings.saving_file = true
            // saveQuery(self,queryObject,pathName)
            let data = {
                // Data you want to send in the request body
                file_id: file_id,
                query_content: JSON.stringify(this.appCurrentSettings.currentDynamicParamString),
                };
            axios
            .post(this.$root.app_api_endpoint+'/save_query'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.naming_file_modal_dynamic = false
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings['is_open_saved_file'] = true 
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings['is_edited_without_save'] = false
                    message.success('Save Success!');
                    this.appCurrentSettings.currentTask_type = 'DYNAMIC'
                    this.loadDynamicFileList()
                }
            })
            return true
            
        },
        renderDynamicParameter(){
            const regex = /\{\{.+?\}\}/g;
            if(this.appCurrentSettings.current_query_content.match(regex) == null){
                this.appCurrentSettings.currentDynamicParamString.params = {}
                return false
            }
            const params_list = this.appCurrentSettings.current_query_content.match(regex).map(y => y.replaceAll(/\{|\}/g,''))
            // console.log(params_list)
            this.appCurrentSettings.currentDynamicParamString.query = this.appCurrentSettings.current_query_content
            
            
            for (let i = 0; i < params_list.length; i++) {
                if(Object.keys(this.appCurrentSettings.currentDynamicParamString.params).includes(params_list[i])){
                    
                    continue
                }
                console.log('create param',params_list[i])
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]] = ref({})
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].param_name = params_list[i]
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].param_type = ref('TEXT')
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].default_value = null
                this.appCurrentSettings.currentDynamicParamString.params[params_list[i]].possible_value = ref()
                // const element = array[i];
                
            }
            // console.log('list A',Object.keys(this.appCurrentSettings.currentDynamicParamString.params))
            // console.log('list B',params_list)
            let difference = Object.keys(this.appCurrentSettings.currentDynamicParamString.params).filter(x => !params_list.includes(x));
            for (let i = 0; i < difference.length; i++) {
                delete this.appCurrentSettings.currentDynamicParamString.params[difference[i]]
            }

            return true
        },
        loadTablesList(){
            // alert(this.$root.app_api_endpoint+'/list_tables?dataset_id='+this.appCurrentSettings.table_dataset)
            this.appCurrentSettings.is_table_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_tables?dataset_id='+this.appCurrentSettings.table_dataset+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    // this.user = response.data.user
                    this.appCurrentSettings['table_list'] = response.data.data
                    this.appCurrentSettings['table_list_filtered'] = response.data.data
                    this.appCurrentSettings.is_table_list_loading = false
                }
            })
        },
        loadTablesSchema(selected_table){
            this.appCurrentSettings.is_table_schema_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/get_table_schema?dataset_id='+this.appCurrentSettings.table_dataset+'&table_id='+selected_table+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings['table_schema'] = response.data.data
                    this.appCurrentSettings.is_table_schema_loading = false
                    this.appCurrentSettings.table_selected_display = this.appCurrentSettings.table_dataset+'.'+selected_table
                }
            })
        },
        filteredTableList() {
        this.appCurrentSettings['table_list_filtered'] = this.appCurrentSettings.table_list.filter((item) => {
            // Customize filtering logic based on your data structure
            const searchKeywords = this.appCurrentSettings.tableKeywordSearching.toLowerCase().split(/\s+/);
            return searchKeywords.every((keyword) =>
            item.name.toLowerCase().includes(keyword)
            );
        });
        },
        loadFileList(){
            this.appCurrentSettings.saved_query_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_saved_query'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_file_list = response.data.data
                    this.appCurrentSettings.saved_query_file_list_loading = false
                }
        })
        }
        ,loadDynamicFileList(){
            this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/list_saved_dynamic_query'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_dynamic_file_list = response.data.data
                    this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                }
        })
        }
        ,loadScheduleTask(){
            this.appCurrentSettings.saved_query_schedule_task_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/get_shedule_task'+`?timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.saved_query_schedule_task_list = response.data.data
                    this.appCurrentSettings.saved_query_schedule_task_list_loading = false
                }
        })
        }
        ,openFileBrowser(){
            this.appCurrentSettings.is_file_browser_open = !this.appCurrentSettings.is_file_browser_open
            if(this.appCurrentSettings.is_file_browser_open == true){
                if(this.appCurrentSettings.saved_query_file_list.length == 0){
                    this.loadFileList()
                }
                if(this.appCurrentSettings.saved_query_schedule_task_list.length == 0){
                    this.loadScheduleTask()
                }
                if(this.appCurrentSettings.saved_query_dynamic_file_list.length == 0){
                    this.loadDynamicFileList()
                }
            }
        }
        ,openScheduledTask(task_id){
            document.getElementById('queryTablePreviewInfo').style.display = 'none'
            document.getElementById('queryAlertInfo').style.display = 'none'
            this.appCurrentSettings.current_table_column = []
            this.appCurrentSettings.current_table_data_source = []

            this.componentKey++;
            for (let i = 0; i < this.appCurrentSettings.saved_query_schedule_task_list.length; i++) {
                if(task_id == this.appCurrentSettings.saved_query_schedule_task_list[i]['display_name']){
                    this.appCurrentSettings['current_query_content'] = this.appCurrentSettings.saved_query_schedule_task_list[i]['query']
                    this.appCurrentSettings['file_id'] = task_id
                    this.appCurrentSettings.naming_file_modal_schedule = false
                    this.appCurrentSettings['current_friendly_name'] = task_id.split('_')[3]
                    this.appCurrentSettings['is_edited_without_save'] = false
                    this.appCurrentSettings.currentScheduleConfigId = this.appCurrentSettings.saved_query_schedule_task_list[i]['id']
                    this.appCurrentSettings.currentTask_type = 'SCHEDULE'
                    this.appCurrentSettings.is_enable_create_schedule = false
                    this.appCurrentSettings.currentScheduleScheduleCadence = this.appCurrentSettings.saved_query_schedule_task_list[i]['schedule']
                    this.parseScheduleTaskFormatting(this.appCurrentSettings.saved_query_schedule_task_list[i]['schedule'])
                }
                
            }

            this.appCurrentSettings.is_file_browser_open = !this.appCurrentSettings.is_file_browser_open
        },
        
        openSavedFile(file_id,file_type){
            if(this.appCurrentSettings['is_edited_without_save']){
                if (!window.confirm("Do you want to open new file without saving?")) {
                    return false
                    }
            }
            document.getElementById('queryTablePreviewInfo').style.display = 'none'
            document.getElementById('queryAlertInfo').style.display = 'none'
            this.appCurrentSettings.current_table_column = []
            this.appCurrentSettings.current_table_data_source = []



            this.appCurrentSettings.saved_query_file_list_loading = true
            this.appCurrentSettings.saved_query_schedule_task_list_loading = true
            this.appCurrentSettings.saved_query_dynamic_file_list_loading = true
            axios
            .get(this.$root.app_api_endpoint+'/open_saved_query?file_id='+file_id+`&timestamp=${new Date().getTime()}`)
            .then((response_raw) => {
                let response = response_raw.data
                if (response.success == true) {
                    this.appCurrentSettings.saved_query_file_list_loading = false
                    this.appCurrentSettings.saved_query_schedule_task_list_loading = false
                    this.appCurrentSettings.saved_query_dynamic_file_list_loading = false
                    this.appCurrentSettings.naming_file_modal = false
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings['is_open_saved_file'] = true 
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings['is_edited_without_save'] = false
                    this.appCurrentSettings.is_file_browser_open = false
                    if(file_type == 'ADHOC'){
                        this.appCurrentSettings.current_query_content = ''
                        this.appCurrentSettings['current_query_content'] = response.data
                        this.componentKey++;
                        this.appCurrentSettings['current_friendly_name'] = file_id.split('_')[3]
                        this.appCurrentSettings.currentTask_type = 'ADHOC'
                    }else if(file_type == 'DYNAMIC'){

                        this.appCurrentSettings.currentDynamicParamString = JSON.parse(response.data)


                        this.appCurrentSettings.current_query_content = ''
                        this.appCurrentSettings['current_query_content'] = JSON.parse(response.data)['query']
                        this.componentKey++;
                        this.appCurrentSettings['current_friendly_name'] = file_id.split('_')[3]
                        this.appCurrentSettings.currentTask_type = 'DYNAMIC'
                    }
                    


                    
                    
                    message.success('Open Success!');

                    // this.$refs.childComponent.$forceUpdate();
                    
                }
            })

            
            
        },
        closeCurrentFile(){
            if(this.appCurrentSettings['is_edited_without_save']){
                if (!window.confirm("Do you want to close file without saving?")) {
                    return false
                    }
            }

        this.appCurrentSettings['file_id'] = ''
        this.appCurrentSettings['is_edited_without_save'] =  false 
        this.appCurrentSettings['is_open_saved_file'] = false
        this.appCurrentSettings['current_friendly_name'] = ''
        this.appCurrentSettings.currentTask_type = 'ADHOC'
        this.appCurrentSettings.currentScheduleScheduleCadence = 'every day'
        this.appCurrentSettings.is_enable_create_schedule = true
        this.appCurrentSettings.current_query_content = ''
        this.componentKey++;

        },
        test(){
            this.appCurrentSettings.current_query_content = 'xxxxx'
        },
        generateRandomString(seedLength) {
            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let result = "";
            for (let i = 0; i < seedLength; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        },
        generateNewFileId(task_type,friendlyName){
            let currentTimestamp = Math.floor(Date.now() / 1000);
            let uuid = this.generateRandomString(6)
            return `DMS-${task_type}_${currentTimestamp}_${uuid}_${friendlyName}`
        },
        openSaveModal(){
            if(this.appCurrentSettings.currentTask_type == 'ADHOC'){
                if(this.appCurrentSettings['file_id']==''){
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings.naming_file_modal = true
                }else{
                    this.saveQueryFile()
                }
            }else if(this.appCurrentSettings.currentTask_type == 'SCHEDULE'){
                this.appCurrentSettings.naming_file_modal_schedule = true
            }else if(this.appCurrentSettings.currentTask_type == 'DYNAMIC'){
                this.renderDynamicParameter()
                this.appCurrentSettings.naming_file_modal_dynamic = true
            }
            
            
        },
        openScheduleModal(){
                if(this.appCurrentSettings['file_id']==''){
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings.naming_file_modal_schedule = true
                }else{
                    this.saveScheduleTask()
                }
        },
        
        checkRunningResult(mode,job_id,fileDownloadUri){
            
            axios
            .get(this.$root.app_api_endpoint+'/check_query_status?job_id='+job_id+'&mode='+mode+'&result_path='+fileDownloadUri+`&timestamp=${new Date().getTime()}`)
            .then((response) => {
                if (response.data.data.run_state == 'DONE') {
                    this.appCurrentSettings['is_loading_result_preview'] = false
                    this.appCurrentSettings['current_running_job_id'] = response.data.job_id
                    // this.checkRunningResult(mode,response.data.job_id,response.data.resultPathUri)
                    clearInterval(window.checkSubmittedTaskInterval)
                    if(mode=='PREVIEW' && response.data.data.error_message == ''){
                        message.success('Finish')
                        this.appCurrentSettings.current_table_data_source = []
                        this.appCurrentSettings.current_table_column = []
                        this.appCurrentSettings.current_table_data_source = response.data.data.result.map((item, index) => ({
                            key: index.toString(),
                            ...item
                            }));
                        
                        if(this.appCurrentSettings.current_table_data_source.length > 0){
                            for (let [key_] of Object.entries(this.appCurrentSettings.current_table_data_source[0])) {
                            if(key_=='key'){
                                continue
                            }
                                this.appCurrentSettings.current_table_column.push({'title':key_,'dataIndex':key_, 'key':key_})
                            }
                        }
                        
                    }else if (response.data.data.error_message != ''){
                        message.error(response.data.data.error_message.message)
                        document.getElementById('queryTablePreviewInfo').style.display = 'none'
                        document.getElementById('queryAlertInfo').style.display = 'unset'
                        this.appCurrentSettings.queryErrorMessage = response.data.data.error_message.message
                        this.appCurrentSettings.current_table_column = []
                        this.appCurrentSettings.current_table_data_source = []

                    }else if (mode=='FULL_ADHOC_RUN' && response.data.data.error_message == ''){
                        window.open(response.data.data.result_file_url, '_blank');
                        this.appCurrentSettings.current_table_column = []
                        this.appCurrentSettings.current_table_data_source = []

                    }
                }else if(response.data.run_state == 'RUNNING') {
                    console.log('running')
                }
            })

        },
        submitRunData(mode){
            let current_file_id = ''
            if(mode == 'FULL_ADHOC_RUN' && this.appCurrentSettings.file_id == ''){
                current_file_id = this.generateNewFileId('ADHOC','temp_file')
            }else{
                current_file_id = this.appCurrentSettings.file_id
                this.appCurrentSettings['is_loading_result_preview'] = true
            }
            this.appCurrentSettings['is_loading_result_preview'] = true
            let data = {
                // Data you want to send in the request body
                mode: mode,
                file_id: current_file_id,
                query_content: this.appCurrentSettings.current_query_content,
                };
            axios
            .post(this.$root.app_api_endpoint+'/submit_run_query'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    console.log(response.data.data.job_id)
                    this.appCurrentSettings['current_running_job_id'] = response.data.data.job_id
                    
                    window.checkSubmittedTaskInterval = setInterval(this.checkRunningResult, 5000,mode,response.data.data.job_id,response.data.data.resultPathUri);
                    
                    message.success('Submit Success!');
                }
            })
        },
        createTaskSchedule(){
            if(this.appCurrentSettings.file_id!=''){
                this.saveScheduleTask()
                return true
            }
            let regex = /^[a-zA-Z0-9-]*$/;
            let test_name = regex.test(this.appCurrentSettings.current_friendly_name)
            let file_id = null
            if(!test_name || this.appCurrentSettings.current_friendly_name == '' ){
                alert('name can contains A-Z, dash, or space')
                return false
            }
            if(!this.reformattingScheduleString()){return false}
            if(this.appCurrentSettings['file_id'] == ''){
                file_id = this.generateNewFileId('SCHEDULE',this.appCurrentSettings.current_friendly_name)
            }else{
                file_id = this.appCurrentSettings['file_id'] 
            }
            
            this.appCurrentSettings.saving_file = true
            // saveQuery(self,queryObject,pathName)
            let data = {
                // Data you want to send in the request body
                query_path_uri: file_id,
                query: this.appCurrentSettings.current_query_content,
                scheduleCadence: this.appCurrentSettings.currentScheduleScheduleCadenceString,
                };
            axios
            .post(this.$root.app_api_endpoint+'/submit_schedule_task'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.naming_file_modal_schedule = false
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings['is_open_saved_file'] = true 
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings['is_edited_without_save'] = false
                    this.appCurrentSettings.currentTask_type = 'SCHEDULE'
                    message.success('Save Success!');
                    this.loadScheduleTask()
                }
            })
            
        }
        ,

        


        updateMessageInParent(updatedMessage) {
            this.appCurrentSettings['current_query_content'] = updatedMessage;
            this.appCurrentSettings.is_edited_without_save = true
            },
        saveQueryFile(){
            let regex = /^[a-zA-Z0-9-]*$/;
            let test_name = regex.test(this.appCurrentSettings.current_friendly_name)
            let file_id = null
            if(!test_name || this.appCurrentSettings.current_friendly_name == '' ){
                alert('name can contains A-Z, dash, or space')
                return false
            }
            if(this.appCurrentSettings['file_id'] == ''){
                file_id = this.generateNewFileId('ADHOC',this.appCurrentSettings.current_friendly_name)
            }else{
                file_id = this.appCurrentSettings['file_id']
            }
            
            this.appCurrentSettings.saving_file = true
            // saveQuery(self,queryObject,pathName)
            let data = {
                // Data you want to send in the request body
                file_id: file_id,
                query_content: this.appCurrentSettings.current_query_content,
                };
            axios
            .post(this.$root.app_api_endpoint+'/save_query'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.naming_file_modal = false
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings['is_open_saved_file'] = true 
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings['is_edited_without_save'] = false
                    this.appCurrentSettings.currentTask_type = 'ADHOC'
                    message.success('Save Success!');
                    this.loadFileList()
                }
            })
            
        },
        saveScheduleTask(){
            // updateSchedule(response_result['config_id'],response_result['query'],response_result['schedule_cadence'],response_result['file_id'])
            if(!this.reformattingScheduleString()){return false}
            let file_id = this.appCurrentSettings['file_id']
            this.appCurrentSettings.saving_file = true
            let data = {
                // Data you want to send in the request body
                config_id:this.appCurrentSettings.currentScheduleConfigId,
                query: this.appCurrentSettings.current_query_content,
                schedule_cadence: this.appCurrentSettings.currentScheduleScheduleCadenceString,
                file_id: file_id
                };
            axios
            .post(this.$root.app_api_endpoint+'/update_schedule_task'+`?timestamp=${new Date().getTime()}`,data)
            .then((response) => {
                if (response.data.success == true) {
                    this.appCurrentSettings.naming_file_modal_schedule = false
                    this.appCurrentSettings.saving_file = false
                    this.appCurrentSettings['is_open_saved_file'] = true 
                    this.appCurrentSettings['file_id'] = file_id
                    this.appCurrentSettings['is_edited_without_save'] = false
                    this.appCurrentSettings.update_schedule_task_modal = false
                    message.success('Save Success!');
                    this.loadScheduleTask()
                }
            })
            
        },
        reGenerateDayList(){
            if(this.appCurrentSettings.currentScheduleScheduleCadence=='every week'){
                this.appCurrentSettings.scheduleDayList = []
                this.appCurrentSettings.selectedScheduleDayListValue =[]
                let date_list = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
                for (let month_list_i = 0; month_list_i < 7; month_list_i++) {
                    this.appCurrentSettings.scheduleDayList.push({label: date_list[month_list_i],value: date_list[month_list_i]}) 
                }
            }else if(this.appCurrentSettings.currentScheduleScheduleCadence=='every month'){
                this.appCurrentSettings.scheduleDayList = []
                this.appCurrentSettings.selectedScheduleDayListValue =[]
                for (let month_list_i = 0; month_list_i < 31; month_list_i++) {
                    this.appCurrentSettings.scheduleDayList.push({label: (month_list_i+1).toString(),value: (month_list_i+1).toString().toLowerCase()}) 
                }
            }
        },
        parseScheduleTaskFormatting(scheduleString){
            if(!scheduleString.includes('every day') && scheduleString.includes('day')){
                // set weekly task
                this.appCurrentSettings.currentScheduleScheduleCadence = 'every week'
                this.appCurrentSettings.selectedScheduleDayListValue = []
                this.appCurrentSettings.currentScheduleScheduleCadenceTime = dayjs(scheduleString.split(' ').reverse()[0],'HH:mm')
                for (let day_i = 0; day_i < scheduleString.split('every ')[1].split(',').length; day_i++) {
                    if(scheduleString.split(',')[day_i].includes('day')){
                        this.appCurrentSettings.selectedScheduleDayListValue.push(scheduleString.split('every ')[1].split(',')[day_i])
                    }
                    
                }

            }else if(scheduleString.includes('of month')){
                this.appCurrentSettings.currentScheduleScheduleCadence = 'every month'
                this.appCurrentSettings.selectedScheduleDayListValue = scheduleString.split(' of month')[0].split(',')
                this.appCurrentSettings.currentScheduleScheduleCadenceTime = dayjs(scheduleString.split(' ').reverse()[0],'HH:mm')
            }else if(scheduleString.includes('every day')){
                this.appCurrentSettings.currentScheduleScheduleCadence = 'every day'
                this.appCurrentSettings.selectedScheduleDayListValue = []
                this.appCurrentSettings.currentScheduleScheduleCadenceTime = dayjs(scheduleString.split(' ').reverse()[0],'HH:mm')
            }else{
                this.appCurrentSettings.currentScheduleScheduleCadence = 'every day'
                this.appCurrentSettings.selectedScheduleDayListValue = []
                this.appCurrentSettings.currentScheduleScheduleCadenceTime = null
            }
        },
        reformattingScheduleString(){
            if(this.appCurrentSettings.currentScheduleScheduleCadenceTime == '' || this.appCurrentSettings.currentScheduleScheduleCadenceTime == null){
                alert('Please select time')
                return false
            }
            let selected_time = dayjs(this.appCurrentSettings.currentScheduleScheduleCadenceTime).format('HH:mm')
            // let selected_time =  this.appCurrentSettings.currentScheduleScheduleCadenceTime
            if(this.appCurrentSettings.currentScheduleScheduleCadence == 'every day'){
                this.appCurrentSettings.currentScheduleScheduleCadenceString = 'every day ' + selected_time
            }else if(this.appCurrentSettings.currentScheduleScheduleCadence == 'every week'){
                if(this.appCurrentSettings.selectedScheduleDayListValue.length == 0){
                    alert('Please select day list')
                    return false
                }
                this.appCurrentSettings.currentScheduleScheduleCadenceString = 'every '+this.appCurrentSettings.selectedScheduleDayListValue.join(',').toLowerCase() + ` ${selected_time}`
            }else if(this.appCurrentSettings.currentScheduleScheduleCadence == 'every month'){
                if(this.appCurrentSettings.selectedScheduleDayListValue.length == 0){
                    alert('Please select day list')
                    return false
                }
                this.appCurrentSettings.currentScheduleScheduleCadenceString = this.appCurrentSettings.selectedScheduleDayListValue.join(',') + ` of month ${selected_time}`
            }
            return true
        }
    },
    

    created() {
        this.loadTablesList()
    },
    mounted(){
        // CodeMirror.fromTextArea(document.getElementById('main-code-editor'),{
        //     lineNumbers:true
        // })
    }

}) 

</script>
<style scoped>
.product-item{
    margin-right: 20px;
    width: 180px;
    float: left;
}
.product-header{
    margin-top: 20px;
}
.product-subheader{
    color: #929292;
    font-size: 0.8em;
}
.parent-desc{
    background-color: rgba(0, 0, 0, 0.02);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.desc p {
  margin-bottom: 1em;
  
}
.top-tap{
    float:left;
     width:100%;
     display:inline-flex!important
}
.edit-area {
  min-height: 400px;
    max-height: 400px;
    overflow: scroll;
    border: 1px solid #999999;
}
.display-result-table{
    white-space: nowrap;
    text-overflow: ellipsis;
}
.display-result-table li.ant-pagination-options div {
    display: none!important;
}
.render-table-list-result{
    padding: 2px;
    font-size: 0.8em;
    font-weight: 200;
}
.render-table-schema-result{
    padding: 2px;
    font-size: 0.8em;
    font-weight: 200;
}
.parameter-space{
    width: 100%!important;
}

.table-list::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f1f1f100; /* or add it to the track */
}

.table-list::-webkit-scrollbar-thumb{
  background: #a7a7a770;
}

.table-list{
overflow-x:hidden;
overflow-y:scroll;
}
</style>